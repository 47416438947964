import React from 'react'
import './comming-soon.css'

const CommingSoon = () => {
  return (
    <main>
        <div className="centered-text">
            <span>
                ¡Entra, reserva y birrea!
            </span>

            <h1>
                Muy Pronto...
            </h1>
        </div>
    </main>
  )
}

export default CommingSoon